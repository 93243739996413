<template>
    <div id="title">
        <div class="title_top">
            <slot name="zh">
                <h5>公司简介</h5>
            </slot>
            <slot name="en"><span>INTRODUCE</span></slot>
        </div>
        <div class="title_b"></div>
    </div>
</template>

<script>
export default {
  name: 'tittle'
}
</script>

<style lang="less" scoped>
#title {
    height: 4rem;
    min-width: 25rem;
    height: 3.75rem;
    width: 100%;
}
.title_top span {
    color: #28acff;
    font-size: 1.125rem;
}
h5 {
    background-color: #087fff;
    font-size: 1.75rem;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    line-height: 2rem;
    margin-right: 1.5625rem;
}
span{
    font-style: italic;
}
.title_b {
    height: 1rem;
    width: 3.75rem;
    box-sizing: border-box;
    border-bottom: 0.3125rem #A4A4A4 solid;
    opacity: 0.3;
}
</style>
