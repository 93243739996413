<template>
    <div id="customer">
        <div class="home_bg w100">
            <img src="../assets/imgs/top-customer1.jpg" alt="top-customer.png" class="w100" />
            <Header></Header>
            <Culture>
                <h3 slot="title1">典型客户</h3>
                <p slot="title3">TYPICAL CUSTOMER</p>
            </Culture>
        </div>
        <!-- 工惠通 -->
        <div id="adv" class="adv w1200">
            <div class="adv_l">
                <img src="../assets/imgs/ght-logo（red）.png" alt="ght-logo（red）.png" class="fl" />
                <h3>工惠通 - 智慧工会服务平台</h3>
                <p>
                    FESCO工惠通智慧工会服务致力于打造工会领域全面融合的生态体系，目前已成功面党政机关、事业单位、央企国企、外资企业、金融、互联网、教育、制造业等不同性质行业工会推广，为客户工会提供集财务、慰问、福利、工会上线活动、咨询、人员外包、软件为一体的线上线下的数字化工会解决方案。
                </p>
                <p>
                    工会管理系统实现了线上工会的智慧工会系统，为员工提供便捷的工会服务。
                </p>
            </div>
            <div class="adv_r">
                <img src="../assets/imgs/sxnl.png" alt="ght.png" />
            </div>
        </div>
        <div id="customer1" class="customer w1200">
            <div class="customer_t">
                <Title>
                    <h5 slot="zh">部分客户名录</h5>
                    <span slot="en">CUSTOMER LIST</span>
                </Title>
            </div>
            <img src="../assets/imgs/company.png" alt="合作公司" />
        </div>
        <!-- Footer -->
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../components/Header'
import Title from '../components/Title'
import Culture from '../components/Culture'
import Footer from '../components/Footer/FootSec.vue'
export default {
  name: 'Customer',
  data () {
    return {}
  },
  mounted () {},
  components: {
    Header,
    Culture,
    Title,
    Footer
  }
}
</script>
<style scoped lang="less">
.home_bg {
    position: relative;
}
#customer .adv {
    height: 25rem;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
}
.adv .adv_l {
    width: 60%;
}
.adv .adv_l h3 {
    font-size: 2.375rem;
    font-style: italic;
    font-weight: bold;
    color: #333333;
    line-height: 5rem;
    padding-left: 6rem;
    margin-bottom: 1rem;
}
.adv .adv_l p {
    font-size: 1.5rem;
    color: #666666;
    line-height: 3.5rem;
}
.adv .adv_r {
    width: 40%;
    position: relative;
    top: -180px;
}
.adv .adv_r img {
    width: 100%;
}

#customer .customer {
    margin-bottom: 50px;
    .customer_t {
        margin: 3rem 0 1.5rem 0;
    }
}
.customer img {
    width: 100%;
}
</style>
